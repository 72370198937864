/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 30번째 로그에서는 깃헙 레지스트리, 에저 클라우드 장애, 클라우드 서비스 일본 리전오픈 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.insightbook.co.kr/"
  }, "도서출판 인사이트 – 프로그래밍, IT 관련 서적을 발간합니다.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--announcing-github-package-registry"
  }, "깃허브 패키지 레지스트리(Github Package Registry) 베타 발표 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/"
  }, "npm | the ❤ of the modern development community")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubygems.org/"
  }, "RubyGems.org | your community gem host")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubygems.org/pages/sponsors"
  }, "Sponsors | RubyGems.org | your community gem host")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.ycombinator.com/item?id=7344503"
  }, "Rubygems.org AWS bill for Feb 2014 | Hacker News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rubytogether/board/issues/5"
  }, "Clarify and make more prominent the role of the RubyGems partnership #5 | GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hub.docker.com/"
  }, "Docker Hub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.docker.com/ee/"
  }, "Docker Enterprise | Docker Documentation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "coupa.ng/bhbdBb"
  }, "쿠팡 - 업무와 일상을 정리하는 새로운 방법 Notion")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/notionhq/status/963577523320717312"
  }, "Notion on Twitter: \"@brendantnorris We're definitely thinking about an API, but ...\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://partners.coupang.com/"
  }, "Coupang Partners")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2019/05/amazon-documentdb-available-in-asia-pacific-tokyo-and-seoul-regions-with-r5-instances/"
  }, "Amazon DocumentDB(MongoDB 호환), 이제 R5 인스턴스를 통해 아시아 태평양(도쿄) 및 아시아 태평양(서울) 리전에서 사용 가능")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2019/05/amazon-documentdb-now-supports-per-second-billing/?nc1=h_ls"
  }, "Amazon DocumentDB (with MongoDB compatibility) now supports per-second billing")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ec2/instance-types/r5/?nc1=h_ls"
  }, "Amazon EC2 R5 Instances - memory intensive compute workloads")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/about-aws/whats-new/2019/05/aws_lambda_adds_support_for_node_js_v10/"
  }, "AWS Lambda adds support for Node.js v10")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/amazon-linux-2/?nc1=h_ls"
  }, "Amazon Linux 2")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/AmazonECS/latest/developerguide/ecs-optimized_AMI.html"
  }, "Amazon ECS-optimized AMIs - Amazon Elastic Container Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/FacebookforDevelopers/videos/422572928569998/"
  }, "F8 2019 Day 1 Keynote")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/FacebookforDevelopers/videos/336919593660160/"
  }, "F8 2019 Day 2 Keynote")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=p-6WnQLHgow"
  }, "Mark Zuckerberg's Awkward Privacy Joke at Facebook's F8 Conference - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/gcp/gcp-is-building-its-second-japanese-region-in-osaka"
  }, "GCP is building its second Japanese region in Osaka | Google Cloud Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/%EB%94%A5%EB%9F%AC%EB%8B%9D-%EC%B6%94%EC%B2%9C-%EC%8B%9C%EC%8A%A4%ED%85%9C-in-production-fa623877e56a"
  }, "딥러닝 추천 시스템 in production – 당근마켓 팀블로그 – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/ko-kr/status/history/"
  }, "Azure 상태 기록 | Microsoft Azure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.microsoft.com/en-us/microsoft-365"
  }, "Microsoft 365")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.publickey1.jp/blog/19/microsoft_azuredns.html"
  }, "(일본어) Microsoft Azure、DNS 설정 변경 실패로 전세계 서비스 장애. 일본 황금 연휴 기간 중, 불행중 다행인가?－ Publickey")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/aurora/global-database/?nc1=h_ls"
  }, "Amazon Aurora 글로벌 데이터베이스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.publickey1.jp/blog/19/oracle_cloud6.html"
  }, "(일본어) Oracle Cloud 도쿄 리전 개설. 오늘부터 가동 개시. 6개월 이내에 오사카 리전도 개설 예정－ Publickey")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=WknMhk9J_EE"
  }, "Osaka Bang! (Eng sub) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.samsung.com/kr/"
  }, "삼성전자 뉴스룸(Samsung Korea Newsroom) - 삼성전자 대표 소통 채널")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/oracle/?nc1=h_ls"
  }, "오라클 데이터베이스 | 오라클 서버 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.canalys.com/newsroom/cloud-market-share-q4-2018-and-full-year-2018"
  }, "Canalys Newsroom- Cloud market share Q4 2018 and full year 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://biz.chosun.com/site/data/html_dir/2019/05/09/2019050901303.html"
  }, "넷플릭스 콘텐츠 추천의 비결 ‘태거(Tagger)' - Chosunbiz > 테크 > ICT/미디어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Tag_cloud"
  }, "Tag cloud - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://audioclip.naver.com/channels/1807/"
  }, "stdout.fm : 오디오클립")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://webmasters.googleblog.com/2019/05/the-new-evergreen-googlebot.html"
  }, "Official Google Webmaster Central Blog: The new evergreen Googlebot")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.google.com/search/docs/guides/rendering"
  }, "Google 검색에서의 렌더링 이해 | 검색 | Google Developers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/daangn/3d0197c137ec43d18ff739b5b254a3c8"
  }, "당근마켓 팀")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/toss-design-system-959822692bec4f879450e361c623cf2a"
  }, "toss, design, system.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/search?q=%22toss%2C+design%2C+system.%22&hl=en"
  }, "\"toss, design, system.\" - Google Search")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zeit.co/blog/serverless-pre-rendering"
  }, "Introducing Serverless Pre-Rendering (SPR) - ZEIT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/kjk/notionapi"
  }, "kjk/notionapi: Unofficial Go API for Notion.so")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/understanding_aws_vpc"
  }, "만들면서 배우는 AWS VPC 입문: 아마존 웹 서비스 네트워크의 기초 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.literatureandlatte.com/scrivener/overview"
  }, "Scrivener | Literature & Latte")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@timetolose/my-interview-with-keith-blount-a49c7764f26f"
  }, "I wasn’t a programmer, but I created Scrivener – Francesco Cordella – Medium")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/vpc/latest/userguide/VPC_Scenario2.html"
  }, "시나리오 2: 퍼블릭 서브넷과 프라이빗 서브넷이 있는 VPC(NAT) - Amazon Virtual Private Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/TeX"
  }, "TeX - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/yukihiro_matz/how-emacs-changed-my-life"
  }, "How Emacs changed my life")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
